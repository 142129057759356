<template>
  <b-modal id="dialog_group_edit" size="lg">
    <template #modal-title>
      <h3>แก้ไขกลุ่มผู้ใช้งาน</h3>
    </template>
    <template #modal-footer="{ok,close}">
      <b-button variant="primary" @click="ok()">บันทึก</b-button>
      <b-button variant="secondary" @click="close()">ยกเลิก</b-button>
    </template>
    <div>
      <b-form v-if="show">
        <div>
          <b-form-group label-cols="4" label-cols-lg="2" label="ชื่อกลุ่ม" label-align="right" class="mb-0">
            <b-form-input></b-form-input>
          </b-form-group>
          <hr>
          <div class="text-right mb-3" >
            <a href="javascript:;" class="btn btn-primary">+</a>
          </div>
           <b-form-group label-cols="4" label-cols-lg="2" label="รายชื่อพื้นที่" label-align="right" class="mb-0">
              <b-input-group>
                <select class="form-control"><option>101 เขตพระนคร</option><option>204 เขตพระโขนง</option><option>204 เขตพระโขนง</option></select>
                <b-input-group-append>
                  <b-button variant="outline-danger">X</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <select class="form-control"><option>101 เขตพระนคร</option><option>204 เขตพระโขนง</option><option>204 เขตพระโขนง</option></select>
                <b-input-group-append>
                  <b-button variant="outline-danger">X</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            
        </div>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
  export default {
    data() {
      return {
        show: true
      }
    }
  }
</script>