<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
      <b-card >
        <b-tabs >
          <b-tab class="pt-4" title="พื่นที่จัดส่ง" active>
            <b-card-text>
               <place_location></place_location>
            </b-card-text>
          </b-tab>
          <b-tab class="pt-4" title="จัดกลุ่มพืนที่จัดส่ง">
            <b-card-text class="mt-2">
              <place_group></place_group>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
  </div>
</template>
<script>

  
  import place_group from "./view/page.group.vue";
  import place_location from "./view/page.location.vue";

  export default {
    components: {
      place_group : place_group,
      place_location : place_location,
    },
    data() {
      return {
      }
    }
  };
</script>
<style>

</style>
