<template>
  <div>
    <div class="pb-4">
      <b-button size="md"  variant="primary" v-b-modal.dialog_group_add>เพิ่ม</b-button>
      <b-button size="md"  variant="danger" v-b-modal.dialog_group_remove>ลบรายการ</b-button>
    </div>
    <b-table 
      hover
      bordered
      small
      responsive 
      :fields="fields" 
      :items="items"
    >
      <template #cell(id)>
        <b-form-checkbox
        
        ></b-form-checkbox>
      </template>
      <template #cell(action)>
        <b-button size="sm" variant="secondary" v-b-modal.dialog_group_edit>แก้ไข</b-button>
      </template>
      
    </b-table>
    <div>
      <dialog_group_add></dialog_group_add>
      <dialog_group_edit></dialog_group_edit>
      <dialog_group_remove></dialog_group_remove>
    </div>
  </div>
</template>
<script>
  import dialog_group_add from "./dialog.group.add.vue";
  import dialog_group_edit from "./dialog.group.edit.vue";
  import dialog_group_remove from "./dialog.group.remove.vue";

  export default {
    components: {
      dialog_group_add : dialog_group_add,
      dialog_group_edit : dialog_group_edit,
      dialog_group_remove : dialog_group_remove
    },
    data() {
      return {
        fields: [{
          key: 'id',
          label: 'เลือก',
          tdClass: 'text-center pr-0',
          thClass: 'text-center',
          thAttr: {
            width:'10px'
          }
        },{
          key: 'name',
          label: 'กลุ่มพื้นที่จัดส่ง',
          sortable: true, 
          sortDirection: 'desc',
          thClass: 'text-center'
        },{
          key: 'locations',
          label: 'รายการพื้นที่จัดส่ง',
          sortable: true, 
          sortDirection: 'desc',
          thClass: 'text-center'
        },{
          key: 'action',
          label: 'ดำเนินการ',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        }],
        items: [
          {
            id : 1,
            name : 'กลุ่มพื้นที่หลักสี่',
            locations : 'สนามบินดอนเมือง,เจ้เล้ง',
            action : 'a'
          }
        ]
      };
    }
  };
</script>