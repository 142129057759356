<template>
  <b-modal id="dialog_group_remove" title="Remove Group">
    <template #modal-title>
      <h3>ลบกลุ่มผู้ใช้งาน</h3>
    </template>
    <template #modal-footer="{ok,close}">
      <b-button variant="danger" @click="ok()">ลบเลย</b-button>
      <b-button variant="secondary" @click="close()">ยกเลิก</b-button>
    </template>
    <div>
    ต้องการที่จะลบกลุ่มผู้ใช้งานดังต่อไปนี้ ?
    </div>
  </b-modal>
</template>
<script>
  export default {
    data() {
      return {
        show: true
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      }
    }
  }
</script>