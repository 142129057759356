<template>
  <div>
    <div class="pb-4">
      <b-button size="md"  variant="primary" v-b-modal.dialog_location_add>เพิ่ม</b-button>
      <b-button size="md"  variant="danger" v-b-modal.dialog_location_remove>ลบรายการ</b-button>
    </div>
    <b-table 
      hover
      bordered
      small
      responsive 
      :fields="fields" 
      :items="items"
    >
      <template #cell(id)>
        <b-form-checkbox
        
        ></b-form-checkbox>
      </template>
      <template #cell(action)>
        <b-button size="sm" variant="secondary" v-b-modal.dialog_location_edit>แก้ไข</b-button>
      </template>
    </b-table>
    <div>
      <dialog_location_add></dialog_location_add>
      <dialog_location_edit></dialog_location_edit>
      <dialog_location_remove></dialog_location_remove>
    </div>
  </div>
</template>
<script>
    import dialog_location_add from "./dialog.location.add.vue";
    import dialog_location_edit from "./dialog.location.edit.vue";
    import dialog_location_remove from "./dialog.location.remove.vue";

  export default {
    components: {
      dialog_location_add : dialog_location_add,
      dialog_location_edit : dialog_location_edit,
      dialog_location_remove : dialog_location_remove
    },
    data() {
      return {
        fields: [{
          key: 'id',
          label: 'เลือก',
          tdClass: 'text-center pr-0',
          thClass: 'text-center',
          thAttr: {
            width:'10px'
          }
        },{
          key: 'type',
          label: 'ประเภท',
          sortable: true, 
          sortDirection: 'desc',
          thClass: 'text-center'
        },{
          key: 'name',
          label: 'ชื่อสถานที่',
          sortable: true, 
          sortDirection: 'desc',
          thClass: 'text-center'
        },{
          key: 'address',
          label: 'ที่อยู่',
          sortable: true, 
          sortDirection: 'desc',
          thClass: 'text-center'
        },{
          key: 'action',
          label: 'ดำเนินการ',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center'
        }],
        items: [
          {
            id : 1,
            name : 'แขวงบางจาก',
            type : 'district',
            remark : '',
            address : 'แขวงบางจาก เขตพระโขนง กรุงเทพมหานคร',
            action : 'a'
          }, {
            id : 2,
            name : 'เขตพระนคร',
            type : 'district',
            remark : '',
            address : 'เขตพระนคร กรุงเทพมหานคร',
            action : 'a'
          }, {
            id : 3,
            name : 'วันหัวลำโพง',
            type : 'temple',
            remark : '',
            address : 'เขตพระโขนง กรุงเทพมหานคร',
            action : 'a'
          }, {
            id : 4,
            name : 'ตึกใบหยก',
            type : 'palce',
            remark : '',
            address : 'ตึกใบหยก เขตวัฒนา',
            action : 'a'
          }
        ]
      };
    }
  };
</script>