<template>
  <b-modal id="dialog_location_add" size="lg">
    <template #modal-title>
      <h3>เพื่มพื้นที่จัดส่ง</h3>
    </template>
    <template #modal-footer="{ok,close}">
      <b-button variant="primary" @click="ok()">บันทึก</b-button>
      <b-button variant="secondary" @click="close()">ยกเลิก</b-button>
    </template>
    <div>
      <b-form v-if="show">
        <div>
          <b-form-group label-cols="4" label-cols-lg="2" label="ประเภท" label-align="right" class="mb-0">
            <select class="form-control">
                <option>district</option>
                <option>temple</option>
                <option>place</option>
            </select>
          </b-form-group>
          <b-form-group label-cols="4" label-cols-lg="2" label="ชื่อพื้นที่" label-align="right" class="mb-0">
            <b-form-input></b-form-input>
          </b-form-group>
          <b-form-group label-cols="4" label-cols-lg="2" label="Address" label-align="right" class="mb-0">
            <b-form-input></b-form-input>
          </b-form-group>
         <b-form-group label-cols="4" label-cols-lg="2" label="จังหวัด" label-align="right" class="mb-0">
            <select class="form-control">
                <option>กรุงเทพมหานคร</option>
                <option>นครปฐม</option>
            </select>
          </b-form-group>
          <b-form-group label-cols="4" label-cols-lg="2" label="อำเภอ" label-align="right" class="mb-0">
            <select class="form-control">
                <option>เขตพระนคร</option>
                <option>เขตพรโขนง</option>
            </select>
          </b-form-group>
          <b-form-group label-cols="4" label-cols-lg="2" label="ตำบล" label-align="right" class="mb-0">
            <select class="form-control">
                <option>แขวงบางจาก</option>
            </select>
          </b-form-group>
          <b-form-group label-cols="4" label-cols-lg="2" label="Remark" label-align="right">
            <b-form-textarea
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
  export default {
    data() {
      return {
        show: true
      }
    }
  }
</script>